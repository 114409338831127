@import 'src/assets/styles/config/variables';

.answer {
  &__group {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    @media(max-width: $from-sm) {
      row-gap: 2px;
    }
  }

  &__item {
    margin: 0;
    font-size: 18px;
    line-height: 1.3;
    user-select: none;

    @media(max-width: $from-sm) {
      font-size: 14px;
    }

    @media(max-width: $from-xs) {
      font-size: 12px;
    }
  }

  &__item input:hover ~ span,
  &__item input:focus ~ span {
    color: var(--green);
  }

  &__item input:disabled {
    & ~ span {
      opacity: .6;
    }

    &:hover ~ span {
      color: var(--text);
    }
  }

  &_error &__item input ~ span {
    border-color: var(--red);
  }

  &__radio &__text {
    padding-top: 16px;

    @media(max-width: $from-sm) {
      padding-top: 10px;
    }
  }

  &__additional {
    margin-top: 24px;
  }
}

.textarea-wrap > div {
  display: block!important;
}


.errorText {
  display: block;
  color: var(--warning);
  font-size: 14px;
  font-weight: 500;
  max-height: 0px;
  overflow: hidden;
  line-height: 1.2;
  transition: max-height .5s ease;


  @media(max-width: $from-sm) {
    font-size: 12px;
  }

  @media(max-width: $from-xs) {
    font-size: 10px;
  }
  span {
    display: block;
    padding-top: 8px;
  }
}
.has-error + .errorText {
  max-height: 25px;
}
