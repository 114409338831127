@import 'src/assets/styles/config/variables';

.question {
  position: relative;
  background: var(--white);
  padding: 40px 48px;
  border-radius: 16px;


  @media(max-width: $from-md) {
    padding: 24px 32px;
  }

  @media(max-width: $from-sm) {
    padding: 24px;
    border-radius: 12px;
  }
  @media(max-width: $from-xs) {
    padding: 16px;
  }


  & ~ & {
    margin-top: 24px;

    @media(max-width: $from-sm) {
      margin-top: 12px;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    font: 700 24px/1.334 var(--font);
    margin-bottom: 24px;

    @media(max-width: $from-sm) {
      font-size: 16px;
      margin-bottom: 10px;
    }

    @media(max-width: $from-xs) {
      font-size: 14px;
    }
  }

  &__num {
    min-width: 36px;
    max-width: 36px;

    @media(max-width: $from-sm) {
      min-width: 24px;
      max-width: 24px;
    }
  }
  &__text {

  }


  &.has-error > &__num {
    color: var(--red);
  }

  //.skeleton {
  //  background-color: var(--metal);
  //  background-image: linear-gradient(90deg, var(--metal), var(--medium-gray), var(--metal));
  //}
}
