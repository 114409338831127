@import 'src/assets/styles/config/variables';
@import 'src/assets/styles/config/mixins';


.pageWrap {
  @include pageTemplate;
  display: flex;
  align-items: center;
  justify-content: center;
}
