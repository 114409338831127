.curtain-head {
  margin-bottom: 36px;
  margin-right: 44px;
  width: calc(100% - 44px);
  pointer-events: none;

  &__panel {
    display: flex;
    justify-content: space-between;
    position: relative;
    color: var(--text);
    pointer-events: auto;
  }

  &__tools {
    flex-shrink: 0;
    position: relative;
    top: 4px;
    margin-right: -12px;

    .btn {
      position: relative;
      top: auto;
      right: auto;
    }
    .btn ~ .btn {
      margin-left: 44px;
    }
  }

  &__logo {
    display: block;
    width: 48px;
    height: 48px;
    margin-left: 50px;
  }

  &.is-sticky {
    position: sticky;
    z-index: 5;
    top: -24px;
    margin-top: -24px;
    margin-bottom: 20px;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 80%;
      background: var(--white);
      box-shadow: 0 0 20px 20px var(--white);
      content: '';
    }
  }

  &.is-sticky &__panel {
    padding: 24px 0 16px;
  }
}