/* -------------------- Inter -------------------- */

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: local('Inter Regular'), local('Inter-Regular'),
       url('../../fonts/Inter-Regular.woff2') format('woff2'),
       url('../../fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: local('Inter Medium'), local('Inter-Medium'),
       url('../../fonts/Inter-Medium.woff2') format('woff2'),
       url('../../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: local('Inter Bold'), local('Inter-Bold'),
       url('../../fonts/Inter-Bold.woff2') format('woff2'),
       url('../../fonts/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
