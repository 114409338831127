@import '../../assets/styles/config/variables';
@import '../../assets/styles/config/mixins';
@import '../../assets/styles/config/global';

.stepper {
  position: fixed;
  top: 48px;
  left: 48px;
  right: 48px;
  z-index: 2;
  background: var(--background);
  overflow: hidden;

  @media(max-width: $from-md) {
    left: 40px;
    right: 40px;
  }
  @media(max-width: $from-sm) {
    left: 16px;
    right: 16px;
  }
}

.stepper__wrap {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;

  @media(max-width: $from-md) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media(max-width: $from-sm) {
    column-gap: 0;
  }
}


.stepper__step {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(.last) {
    column-gap: 12px;
  }
  &.last .stepper__textWrap {
    position: relative;
    left: 12px;
  }

  &.disabled {
    pointer-events: none;

    .stepper__number span {
      pointer-events: none;
      cursor: text;
      background: var(--light-gray);
      color: var(--gray);
    }
  }

  &.active {
    pointer-events: none;

    .stepper__textWrap {
      //display: block;
      width: 100%;
      opacity: 1;
    }
    & + .stepper__line {
      width: 100%;
      //display: block;
    }
  }

  &.completed {
    .stepper__number span {
      opacity: 0;
    }
    .stepper__icon {
      opacity: 1;
    }
  }
}
.stepper__line {
  //width: 100%;
  //display: none;
  height: 2px;
  width: 0;
  transition: width .8s;
  position: relative;

  &.last {
    opacity: 0;
    margin-right: -6px;

    @media(max-width: $from-sm) {
      margin-right: 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 12px;
    bottom: 0;
    right: 12px;
    background: var(--light-gray);
  }
}
.stepper__number {
  position: relative;

  span {
    border-radius: 50%;
  }

  .icon,
  .stepper__circle {
    transition: opacity .5s ;
  }
}

.stepper__circle {
  z-index: 1;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--white);
  background: var(--green);
  font: 500 24px/1 $font;
  transition-delay: .2s;

  @media(max-width: $from-sm) {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
}

.stepper__icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--light-green);
  border-radius: 50%;
  opacity: 0;
}

.stepper__textWrap {
  //display: none;
  font-size: 14px;
  line-height: 1.4;
  white-space: nowrap;
  width: 0;
  opacity: 0;
  transition:  opacity .3s;


  @media(max-width: $from-sm) {
    font-size: 13px;
  }
}
.stepper__title {
  color: var(--green);
  font-weight: 700;
}
.stepper__amount {
  color: var(--gray);
}
