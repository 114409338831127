@import 'src/assets/styles/config/variables';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  position: relative;
  text-align: center;
  text-decoration: none;
  color: inherit;
  background: transparent;
  border: 0 solid transparent;
  box-sizing: border-box;
  overflow: hidden;
  transition: background .4s, opacity .4s, color .4s;
  cursor: pointer;

  span {
    position: relative;
  }
}

/* * * Sizes * * */
.btn--xxs {
  padding: 5px 24px 4px;
  height: 32px;
  font: 500 14px/1.71 $font;
  border-radius: 3px;

  &[class*="outline"] {
    border-width: 2px;
  }
}
.btn--xs {
  padding: 9px 12px 7px;
  height: 40px;
  font: 500 14px/1.71 $font;
  border-radius: 3px;

  &[class*="outline"] {
    border-width: 2px;
  }
}

.btn--sm,
.btn--md,
.btn--lg {
  padding: 12px 20px 12px;
  height: 48px;
  font: 700 18px/1 $font;
  border-radius: 8px;
  min-width: 200px;

  &[class*="outline"] {
    border-width: 2px;
  }
}

.btn--md {
  //padding: 9px 24px 7px;
}
.btn--lg {
  //padding: 9px 48px 7px;
}

/* * * Designs * * */
.btn--green {
  color: var(--white);
  background: var(--green);

  &:hover,
  &:active {
    background: var(--greenHover);
  }

  &[disabled],
  &.is-disabled, {
    opacity: .4;
    pointer-events: none;
    cursor: default;
  }
}

.btn--green-outline {
  color: var(--green);
  background: var(--white);
  border-color: var(--green);

  &:hover {
    color: var(--jade);
    background: var(--polar);
    border-color: var(--jade);
  }

  &:active {
    color: var(--green);
    background: rgba(0, 177, 107, 0.2);;
  }
}

.btn--green-light {
  color: var(--green);
  background: var(--light-green);

  &:hover {
    color: var(--white);
    background: var(--jade);
  }

  &:active {
    color: var(--white);
    background: var(--jade);
  }
}

.btn--flat-green {
  color: var(--green);

  &:hover, &:active {
    color: var(--text);
  }

  &[disabled],
  &.is-disabled, {
    pointer-events: none;
    cursor: default;

    span {
      opacity: .5;
    }
  }
}

/* * * Wide * * */
.btn--wide {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

/* * * With icons * * */
.btn__icon {
  svg {
    display: block;
    fill: currentColor;
  }
}

.btn__icon--left {
  margin: 0 16px 0 0;

  @media(max-width: $from-sm) {
    margin: 0 12px 0 0;
  }
}
.btn__icon--right {
  margin: 0 0 0 16px;

  @media(max-width: $from-sm) {
    margin: 0 0 0 12px;
  }
}

/* * * Loading * * */
.btn {
  .spinner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
  }

  &[class*="btn--flat"] {
    .spinner {
      left: 6px;
      margin: 0;
      width: 28px;
      font-size: 8px;
    }
  }

  &:not([class*="btn--flat"]).is-loading {
    color: transparent !important;
    transition: none; // all .4s;

    &[disabled],
    &.is-disabled, {
      opacity: 1 !important;
    }

    .btn__icon {
      opacity: 0;
    }

    .spinner {
      color: var(--white);
      opacity: 1;
    }
  }

  &[class*="btn--flat"].is-loading {
    transition: none; // all .4s;

    .btn__icon--left {
      opacity: 0;
    }
    .spinner {
      opacity: 1;
    }
  }

  &.btn--green-light.is-loading,
  &.btn--green-outline.is-loading {
    .spinner {
      color: var(--green);
    }
  }
}



.btn--green-icon {
  color: var(--green);
  min-width: 1px;
  padding: 0;

  @media(max-width: $from-sm) {
    font-size: 16px;
  }

  svg {
    fill: currentColor;
    transition: fill .4s ;
    width: 48px;
    height: 48px;

    @media(max-width: $from-sm) {
      width: 40px;
      height: 40px;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &:hover,
  &:active {
    color: var(--greenHover);
  }


}
