@import 'src/assets/styles/config/variables';

.popup {
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  right: 0;
  bottom: -100px;
  padding-bottom: 100px;
  width: 100%;
  font: 400 var(--fs-16) $font;
  pointer-events: none;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &__capsule {
    display: table;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;

    &-inner {
      display: table-cell;
      vertical-align: middle;
      padding: 48px;
      text-align: center;
    }
  }

  &__perspective {
    position: relative;
    z-index: 10;
    width: 100%;
    max-width: 545px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(37, 53, 49, .3);
    transform: translateZ(-1000px);
    animation: fadeOut 400ms forwards;
  }

  &__box {
    position: relative;
    z-index: 30;
    margin-left: auto;
    margin-right: auto;
    padding: 72px 96px;
    background: var(--white);
    box-shadow: var(--shadow-popup);
    box-sizing: content-box;
    border-radius: 8px;
    animation: fadeOut 400ms forwards;
  }

  &__close {
    top: 21px;
    right: 22px;
  }
}

.popup.is-shown {
  pointer-events: auto;

  .popup__box {
    pointer-events: auto;
    animation: fadeIn 400ms forwards;
  }
  .popup__overlay {
    pointer-events: auto;
    animation: fadeIn 400ms forwards;
  }
}

@keyframes fadeIn {
  from { opacity: 0; pointer-events: none; }
  to { opacity: 1; pointer-events: auto; }
}

@keyframes fadeOut {
  from { opacity: 1; pointer-events: none; }
  to { opacity: 0; pointer-events: auto; }
}

.error-popup .popup__box {
  background: var(--light-red);
}