@import 'src/assets/styles/config/variables';

.notice {
  position: relative;
  padding-left: 32px;
  font: 400 14px/1.571428571428571 $font;
  color: var(--text);

  &__icon {
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;

    svg {
      display: block;
      fill: var(--metal);
    }
  }

  &__marker {
    position: absolute;
    z-index: 1;
    top: 0;
    right: -4px;
    width: 7px;
    height: 7px;
    background-color: var(--red);
    border-radius: 50px;
    box-shadow: 0 0 0 2px var(--white);
  }

  & ~ & {
    margin-top: 16px;
  }
}
