@import 'src/assets/styles/config/variables';

.text {
  &--sm {
    font: 400 14px/1.5 $font;
  }
  &--md {
    font: 400 16px/1.5 $font;
  }
  &--lg {
    font: 400 18px/1.71 $font;
  }
}