@import 'src/assets/styles/config/variables';

.property {
  font: 400 16px/1.5 $font;
  letter-spacing: -.02em;
  color: var(--text);

  &__label {
    font: 400 12px/1.334 $font;
    letter-spacing: .01em;
    color: var(--green);
  }
}

@media print {
  .property {
    &__label {
      color: var(--gray);
    }
  }
}