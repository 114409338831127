// toRGB
@function toRGB($color) {
  @return "rgb(" + red($color) + ", " + green($color) + ", " + blue($color)+ ")";
}

// vp
@mixin vp-full {
  height: 100vh;

  @supports (height: var(--vh)) {
    height: var(--vh);
  }
}
@mixin vp-full-min {
  min-height: 100vh;

  @supports (min-height: var(--vh)) {
    min-height: var(--vh);
  }
}
@mixin vw-full {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @supports (width: var(--vw)) {
    width: var(--vw);
    margin-left: calc(var(--vw) / -2);
    margin-right: calc(var(--vw) / -2);
  }
}
@mixin pageTemplate {
  position: relative;
  min-width: 100%;
  color: inherit;
  background-color: var(--background);
  display: block;
  min-height: 100vh;
  min-height: var(--vh);
  font: 500 var(--fs-18) $font;
  box-sizing: border-box;
  padding: 48px 48px 40px;

  @media(max-width: $from-md) {
    padding: 48px 40px 24px;
  }
  @media(max-width: $from-sm) {
    padding: 48px 16px 24px;
  }
  @media(max-width: $from-xs) {
    font: 500 var(--fs-14) $font;
  }
}

// flex
@mixin flex-end { display: flex; flex-flow: column nowrap; justify-content: flex-end; }
@mixin flex-center { display: flex; flex-flow: column nowrap; justify-content: center; }

// drops
@mixin drop-regular { opacity: 0; visibility: hidden; pointer-events: none; transition: opacity var(--trans), visibility var(--trans); }
@mixin drop-regular-show { opacity: 1; visibility: visible; pointer-events: auto; }

// scroll block
@mixin vertical-scroll { overflow-x: hidden; overflow-y: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: none; scrollbar-width: none; }
@mixin horizontal-scroll { overflow-y: hidden; overflow-x: auto; -webkit-overflow-scrolling: touch; -ms-overflow-style: none; scrollbar-width: none; }

@mixin smallDesktop {
  @media (max-width: $to-xl) {
    @content;
  }
}
