@import 'src/assets/styles/config/variables';

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 44px;
  height: 44px;
  color: var(--text);
  box-sizing: border-box;
  overflow: hidden;
  transition: color .4s;
  cursor: pointer;

  svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: currentColor;
    transition: transform .4s;
  }

  &:hover svg {
    transform: rotate(90deg);
  }
}
