@import 'src/assets/styles/config/variables';

.info-block {
  position: relative;
  padding-left: 40px;
  font: 400 16px/1.5 $font;
  letter-spacing: -0.02em;

  &__icon {
    position: absolute;
    left: 0;
    top: -1px;
    fill: var(--green);
    transition: fill .4s;
  }

  &--boxed {
    padding: 16px 16px 16px 56px;
    background-color: var(--white);

    .info-block__icon {
      top: 16px;
      left: 16px;
    }
  }

  &--big {
    max-width: 75%;
    padding: 16px 16px 16px 56px;
    background-color: var(--white);

    .info-block__icon {
      top: 16px;
      left: 16px;
    }
  }

  &--small {
    padding-left: 32px;
    font: 400 14px/1.571 $font;
  }

  &--error &__icon {
    fill: var(--warning);
  }
  &--warning &__icon {
    fill: var(--warning);
  }

  p {
    font: inherit;
    color: inherit;
  }
  p ~ p {
    margin-top: .8em;
  }
}

@media print {
  .info-block {
    //&:not(&--small, &--big) {
    //  padding-left: 40px;
    //}

    &__icon {
      fill: var(--text) !important;
    }
  }
}