@import '../../assets/styles/config/variables';
@import '../../assets/styles/config/mixins';

.header {
  display: flex;
  align-items: center;
  column-gap: 8px;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px;
  background: var(--green);
  box-sizing: border-box;
}

.header__wrap {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;

  button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.header__logo {
  width: 32px;
  height: 32px;
}
.header__title {
  color: var(--white);
  font: 500 16px/1 $font;

  @media(max-width: $to-xs) {
    display: none;
  }
}


.header__nav {
  display: flex;
  align-items: center;
  margin-left: 67px;

  .header__sandwich ~ & {
    margin-left: 18px;
  }
}

.header__nav-link {
  margin-left: 80px;
  font: 400 24px/1.3 $font;
  color: var(--green);
  transition: color .4s ease;

  &:hover, &.is-active {
    color: var(--text);
  }

  // &.is-active {
  //   pointer-events: none;
  // }

  @include smallDesktop {
    margin-left: 40px;
  }
}
.header__user {
  position: absolute;
  right: 16px;
  top: 16px;
}

.header__notices {
  position: relative;
  margin-left: 80px;
  color: var(--green);
  transition: color .4s ease;

  svg {
    display: block;
    fill: currentColor;
  }

  &:hover, &.is-active {
    color: var(--text);
  }

  &.has-newest:before {
    position: absolute;
    top: 2px;
    right: 0;
    width: 11px;
    height: 11px;
    background: var(--red);
    border: 2px solid var(--background);
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
  }

  @include smallDesktop {
    margin-left: 40px;
  }
}

@media print {
  .header {
    display: none;
  }
}
