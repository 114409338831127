@import 'src/assets/styles/config/variables';

.form-control {
  display: inline-block;
  position: relative;

  & > input {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 8px;
    width: 20px;
    height: 20px;
    border: none;
    clip: rect(0 0 0 0);
    appearance: none;

    &[type="radio"] {
      & ~ span {
        min-height: 20px;
        padding: 8px 8px 8px 40px;

        @media(max-width: $from-sm) {
          padding-left: 30px;
        }
      }

      & ~ span:before,
      & ~ span:after {
        position: absolute;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        content: '';
        top: 10px;
        left: 0;
        border-radius: 50%;
        transition: opacity .4s;

        @media(max-width: $from-sm) {
          top: 8px;
        }
        @media(max-width: $from-xs) {
          top: 6px;
        }
      }

      & ~ span:before {
        border: 2px solid var(--medium-gray);
      }

      & ~ span:after {
        opacity: 0;
        visibility: hidden;
        border: 6px solid var(--green);
      }
    }
  }

  & > input ~ span {
    display: inline-block;
    box-sizing: border-box;
    color: currentColor;
    transition: color .4s;
    cursor: pointer;
  }


  & > input:checked ~ span:after {
    opacity: 1;
    visibility: visible;
  }

}
