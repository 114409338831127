@import 'src/assets/styles/config/variables';

.wrap {
  max-width: 630px;
  text-align: center;
}

.title {
  font: 700 32px/1.1667 $font;

  @media(max-width: $from-md) {
    font-size: 28px;
  }
  @media(max-width: $from-sm) {
    font-size: 24px;
  }

}
.description {
  font: 500 18px/1.334 $font;
  max-width: 30em;
  margin-right: auto;
  margin-left: auto;

  @media(max-width: $from-md) {
    font-size: 16px;
  }
  @media(max-width: $from-sm) {
    font-size: 14px;
  }
}

.image {
  width: 100%;
  max-width: 354px;
  height: 313px;
  margin-bottom: 56px;

  @media(max-height: 980px) {
    width: 285px;
    height: 252px;
    margin-bottom: 40px;
  }
  @media(max-width: 1535px) {
    width: 285px;
    height: 252px;
    margin-bottom: 40px;
  }
  @media(max-width: 1280px) {
    width: 200px;
    height: 180px;
  }
  @media(max-height: 850px) {
    width: 200px;
    height: 180px;
  }
}
