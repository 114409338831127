$perc: unquote('%');

$hd:          896px            !default;
$fhd:         1920px            !default;
$minWidth:    1130px            !default;
$maxWidth:    1600px            !default;

$from-xxs:	  320px             !default;
$to-xxs:      $from-xxs - 1     !default;
$from-xs:	    360px             !default;
$to-xs:       $from-xs - 1     	!default;
$from-sm:     560px             !default;
$to-sm:       $from-sm - 1    	!default;
$from-md:     896px             !default;
$to-md:       $from-md - 1   	!default;
$from-xl:     1920px            !default;
$to-xl:       $from-xl - 1      !default;

$padding: 4.167; // 50px / 1200px
$paddingXL: 3.125; // 50px / 1600px

$gapInner: -4.5454545454545%;
$padInner: 4.5454545454545%;

$gapInnerXL: -3.3333333333333%;
$padInnerXL: 3.3333333333333%;

$gap: 10px;

$font: 'Inter', sans-serif;

:root {
  --vh: 100vh;
  --vw: 100vw;
  --green: #489654;
  --greenHover: #2F7D3B;
  --greenRgba40: rgba(0, 170, 107, .4);
  --light-green: #D8E9D9;
  --black: #000;
  --text: #333;
  --textRgb: 51, 51, 51;
  --white: #fff;
  --gray: #7E7E89;
  --medium-gray: #B6B6BE;
  --light-gray: #DFE1E3;
  --metal: #DADADA;
  --skeleton-gray: #E9E9E9;
  --medium-green: #5AC776;
  --red: #FC5A5A;
  --warning: #FC5A5A;
  --background: #F2F3F4;
  --blue: #008DAE;
  --cascading-white: #F6F6F6;
  --light-red: #FFF8F5;
  --jade: #00BE7B;
  --polar: #EDFBF6;
  --jade-second: #00B16B;
  --tropical-rain: #00844B;
  --green-haze: #009156;
  --focus-visible: rgba(0, 170, 107, .3);
  --focus-outline: 0 0 0 2px var(--focus-visible);
  --font: 'Inter', sans-serif;
  --fs-12: 12px/1.334;
  --fs-14: 14px/1.334;
  --fs-16: 16px/1.334;
  --fs-18: 18px/1.334;
  --fs-24: 24px/1.334;
  --trans: .3s ease;
  --transIn: 400ms cubic-bezier(.55, .055, .675, .19);
  --transOut: 800ms cubic-bezier(.215, .610, .355, 1);
  --shadow-regular: 0 2px 6px rgba(0, 0, 0, .05);
  --shadow-drop: 0 5px 12px rgba(51, 51, 51, .15);
  --shadow-popup: 0 5px 15px rgba(0, 0, 0, 0.25);
  --shadow-curtain: 0px 2px 48px rgba(0, 0, 0, 0.1);
}
