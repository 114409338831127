@import 'src/assets/styles/config/variables';

.input-wrapper {
  display: block;
  position: relative;

  &__label {
    margin: 0 0 8px;
    font: 700 var(--fs-18) $font;
    color: var(--text);

    &.is-required:after {
      color: var(--red);
      content: ' \002A';
    }

    &.is-placeholder {
      position: absolute;
      z-index: 5;
      left: 9px;
      top: 10px;
      margin-bottom: 0;
      width: auto;
      font: 400 var(--fs-14) $font;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--gray);
      background: transparent;
      box-shadow: 0 0 6px 2px transparent;
      transform-origin: 0 0;
      transition: none;
      pointer-events: none;
      backface-visibility: hidden;
      box-sizing: border-box;

      &.is-active {
        left: 6px;
        top: -10px;
        background: var(--white);
        box-shadow: 0 0 6px 2px var(--white);
        transform: scale(.904761904761905) translateX(3px);
        opacity: 1;
      }
    }

    &.has-transition {
      transition: all 200ms;
    }
  }

  &__field {
    position: relative;

    textarea,
    input:not([type="file"]) {
      display: block;
      position: relative;
      padding: 12px 20px;
      width: 100%;
      max-width: 100%;
      height: 80px;
      text-overflow: ellipsis;
      text-shadow: none;
      color: var(--text);
      background: var(--white);
      border: 1px solid var(--light-gray);
      border-radius: 12px;
      box-sizing: border-box;
      transition: border-color .4s, box-shadow .4s, min-height .4s;
      outline: none;

      @media(max-width: $from-sm) {
        height: 64px;
        padding: 12px 16px;
      }
      @media(max-width: $from-xs) {
        padding: 8px 12px;
      }

      &:focus {
        border-color: var(--green);
      }
      &:focus-visible {
        box-shadow: var(--focus-outline);
      }
      .input-wrapper.has-error & {
        border-color: var(--red);
      }

      &[readonly] {
        cursor: default;
      }
      &[disabled] {
        background: var(--background);
        cursor: default;
        pointer-events: none;
      }
      &[placeholder] {
        text-overflow: ellipsis;
      }

      &::placeholder,
      &.placeholder:before {
        font-weight: 400;
        color: var(--gray);
        text-overflow: ellipsis;
        transition: opacity .4s;
      }
      &:focus::placeholder,
      &.placeholder:focus:before {
        opacity: 0;
      }

      &::-ms-clear {
        visibility: hidden;
      }
    }

    textarea,
    &--autoGrow:after {
      padding: 12px 20px;
      height: auto;
      min-height: 80px;
      min-width: 100%;
      cursor: text;

      @media(max-width: $from-sm) {
        padding: 12px 16px;
        min-height: 64px;
      }

      @media(max-width: $from-xs) {
        padding: 8px 12px;
      }
    }
  }

  &__field--autoGrow {
    position: relative;

    textarea {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      resize: none;
      overflow: hidden;
      font: 500 18px/1.3 var(--font);

      @media(max-width: $from-sm) {
        font-size: 14px;
      }
      @media(max-width: $from-xs) {
        font-size: 12px;
      }

      &[disabled] {
        min-height: 0;
      }
    }

    &:after {
      display: block;
      white-space: pre-wrap;
      border: 1px solid transparent;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      user-select: none;
      box-sizing: border-box;
      content: attr(data-replicated) ' ';
      overflow-wrap: break-word;
      font: 500 18px/1.3 var(--font);
      padding: 12px 20px;

      @media(max-width: $from-sm) {
        font-size: 14px;
      }

      @media(max-width: $from-sm) {
        padding: 12px 16px;
      }

      @media(max-width: $from-xs) {
        font-size: 12px;
        padding: 8px 12px;
      }
    }

    &.disabled:after {
      min-height: 0;
    }
  }

  &__eye {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: var(--light-gray);
    background: none;
    border: none;
    transition: color .4s;
    cursor: pointer;

    svg {
      display: block;
      width: 22px;
      height: 22px;
      fill: currentColor;
    }

    &:hover, &.is-active {
      color: var(--green);
    }
  }
}
