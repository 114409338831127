@import 'src/assets/styles/config/variables';

.declaration-form {
  max-width: 864px;
  margin-right: 44px;
  padding-top: 128px;

  @media(max-width: $from-md) {
    padding-top: 96px;
  }
  @media(max-width: $from-sm) {
    padding-top: 88px;
  }

  .curtain-head {
    margin-right: 0;
    width: 100%;
  }

  &__caption {
    margin: 0 0 32px;
  }
}

.sectionWrap {
  transition: opacity .5s linear;
}
.fadeIn {
  opacity: 0;
}
.fadeOut {
  opacity: 1;
}



.finishWrap {
  height: calc(var(--vh) - 48px - 40px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: $from-md) {
    height: calc(var(--vh) - 48px - 24px);
  }
}

@import '../../assets/styles/config/variables';
@import '../../assets/styles/config/mixins';
@import '../../assets/styles/config/global';


.form-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;
  margin-top: 32px;

  @media(max-width: $from-sm) {
    margin-top: 24px;
  }
}

.hideBtn {
  pointer-events: none;
  opacity: 0;
}
.disabledBtn {
  opacity: 0.4;
  pointer-events: none;
  transition: opacity .4s ease;
}
