@import 'src/assets/styles/config/variables';

.info-link {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  padding: 16px 56px 16px 16px;
  font: 400 18px/1.34 $font;
  color: var(--text);
  background-color: var(--white);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  .info-link__icon {
    align-self: flex-start;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    margin: 1px 17px 1px 1px;
    fill: var(--green);
  }

  .info-link__eye {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    fill: var(--metal);
    transition: fill .4s ease;
  }

  .info-link__text {
    display: block;
    width: calc(100% - 39px);
  }
}

a.info-link,
button.info-link {
  color: var(--text) !important;
  transition: background-color .4s ease;
  cursor: pointer;

  &:hover, &:active {
    background-color: var(--polar);

    .info-link__eye {
      fill: var(--green);
    }
  }
}