@import 'src/assets/styles/config/variables';

.curtain {
  display: block;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font: 400 var(--fs-16) $font;
  letter-spacing: -0.02em;
  color: var(--text);
  pointer-events: none;

  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, .4);
    animation: fadeOut 400ms forwards;
  }

  &__box {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--white);
    box-shadow: var(--shadow-curtain);
    animation: slideOut 400ms forwards;
  }

  &__scroller {
    padding: 24px 48px 0 32px;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;

    & > div:last-child {
      padding-bottom: 32px;
    }
  }

  &__footer {
    position: relative;
    z-index: 1;
  }
  &__footer-shadow {
    position: absolute;
    left: 0;
    bottom: 100%;
    right: 0;
    height: 24px;
    overflow: hidden;
    pointer-events: none;

    &:before {
      position: absolute;
      left: 0;
      top: 100%;
      right: 0;
      height: 100%;
      box-shadow: 0 0 16px 16px var(--white);
      content: '';
    }
  }

  &__box.is-resizable {
    padding-left: 6px;
    max-width: 100vw;
    box-sizing: border-box;

    .curtain__drag {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 6px;
      cursor: grab;
      user-select: none;

      &-bar {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: var(--green-haze);
        transition: background 400ms;

        &:before {
          position: absolute;
          left: 2px;
          top: 50%;
          margin-top: -12px;
          width: 2px;
          height: 24px;
          background: var(--white);
          border-radius: 4px;
          transition: all 400ms;
          content: '';
        }
      }

      &-shadow {
        position: absolute;
        left: -30;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        pointer-events: none;

        &:before {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 10px;
          box-shadow: 0 10px 15px rgba(16, 164, 128, 0);
          transition: box-shadow 400ms;
          content: '';
        }
      }

      &:hover .curtain__drag-bar {
        background: var(--green);

        &:before {
          transform: scaleY(1.5);
        }
      }
      &:hover .curtain__drag-shadow {
        &:before {
          box-shadow: 0 10px 15px rgba(16, 164, 128, .79);
        }
      }

      &.is-dragging .curtain__drag-bar {
        background: var(--green);

        &:before {
          transform: scaleY(2);
        }
      }
      &.is-dragging .curtain__drag-shadow:before {
        box-shadow: 0 10px 15px rgba(16, 164, 128, .79);
      }

    }
  }

  &__close {
    z-index: 10;
    top: 24px;
    right: 22px;
  }
}

.curtain.is-shown {
  pointer-events: auto;

  .curtain__overlay {
    pointer-events: auto;
    animation: fadeIn 400ms forwards;
  }
  .curtain__box {
    pointer-events: auto;
    animation: slideIn 400ms forwards;
  }
}

@keyframes fadeIn {
  from { opacity: 0; pointer-events: none; }
  to { opacity: 1; pointer-events: auto; }
}

@keyframes fadeOut {
  from { opacity: 1; pointer-events: none; }
  to { opacity: 0; pointer-events: auto; }
}

@keyframes slideIn {
  from { transform: translateX(100%); pointer-events: none; }
  to { transform: translateX(0); pointer-events: auto; }
}

@keyframes slideOut {
  from { transform: translateX(0); pointer-events: none; }
  to { transform: translateX(100%); pointer-events: auto; }
}

@media print {
  .curtain {
    display: block !important;
    position: static;

    &__box {
      position: static;
      width: 100% !important;
      overflow: hidden;
      box-shadow: none;
    }
    &__scroller {
      padding: 0 12px 0 0;
      height: auto;
      overflow: visible !important;

      & > div:last-child {
        padding-bottom: 0;
      }
    }
    &__footer {
      display: none;
    }
    &__overlay {
      position: absolute;
      height: auto;
    }
  }
}