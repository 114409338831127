@import 'src/assets/styles/config/variables';

.file-input {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  position: relative;
  font: 400 14px/1.5 $font;
  letter-spacing: -.01em;
  color: var(--green);
  transition: color .3s;
  cursor: pointer;

  &:hover {
    color: var(--green-haze);
  }

  &__icon {
    flex-shrink: 0;
    margin: 1px 8px 0 0;
    fill: currentColor;
  }

  input ~ &__text:before {
    position: absolute;
    left: -4px;
    top: -4px;
    right: -4px;
    bottom: -2px;
    box-shadow: 0 0 6px 2px transparent;
    border-radius: 4px;
    transition: box-shadow .3s;
    pointer-events: none;
    content: '';
  }
  input:focus-visible ~ &__text:before {
    box-shadow: var(--focus-outline);
  }
}